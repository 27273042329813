














































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FiltersModel } from '../../models/filters/filters.model';
import StatusFilter from './StatusFilter/StatusFilter.vue';
import TypeFilter from './TypeFilter/TypeFilter.vue';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { DateType } from '../../models/filters/date-filter.model';
import moment from 'moment-timezone';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';

import SelectionFilter from '@/shared/components/form/SelectionFilter.vue';

import { AccountingModule } from '@/store/index';
import { AccountingTypes } from '../../models/accounting.model';
import { createDivisionFilter } from '@/shared/services/division/division.helper';

@Component({
  components: { TypeFilter, StatusFilter, SelectionFilter }
})
export default class QbApArFilters extends Vue {
  @Prop() typeFilter: FilterInput<string>;

  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.search();
  }

  accountingModule = AccountingModule;
  form: any;
  excelData: any;

  maxDate = null;

  divisionFilter = createDivisionFilter();

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment().subtract(12, 'days').format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  statusFilter = {
    notSent: new FilterInput({
      condition: FilterConditions.Equal,
      search: 'NOT_SENT',
      field: 'status'
    }),
    error: new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'status'
    }),
    accepted: new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'status'
    }),
    locked: new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'status'
    })
  };

  constructor() {
    super();
    this.form = new FiltersModel();
  }

  search() {
    this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
      this.fromFilter.search,
      [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
    );

    this.toFilter.search = moment(this.toFilter.search, [
      BS_DATEPICKER_FORMAT,
      DATE_API_FORMAT
    ])
      .endOf('day')
      .format(DATE_API_FORMAT);

    this.accountingModule.setFilters(
      TransformFiltersToJson([
        this.typeFilter,
        this.statusFilter.notSent,
        this.statusFilter.error,
        this.statusFilter.accepted,
        this.statusFilter.locked,
        this.toFilter,
        this.fromFilter,
        this.divisionFilter
      ])
    );

    this.$emit('search');
  }

  async excel() {
    this.accountingModule.setFilters(
      TransformFiltersToJson([this.toFilter, this.fromFilter])
    );

    await this.accountingModule.excel(AccountingTypes[this.$route.params.type]);

    if (AccountingModule.excelData) {
      window.open(AccountingModule.excelData.url);
    }
  }
}
