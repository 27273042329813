

















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { FiltersModel } from '../../models/filters/filters.model';
import PeriodsFilter from './PeriodsFilter/PeriodsFilter.vue';
import TypeFilter from './TypeFilter/TypeFilter.vue';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { DateType } from '../../models/filters/date-filter.model';
import moment from 'moment-timezone';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';

import SelectionFilter from '@/shared/components/form/SelectionFilter.vue';
import FileDownload from '@/shared/components/form/FileDownload.vue';

import { AccountingModule } from '@/store/index';
import { AccountingTypes } from '../../models/accounting.model';

@Component({
  components: { TypeFilter, PeriodsFilter, SelectionFilter, FileDownload }
})
export default class PerdiemFilters extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.search();
  }

  accountingModule = AccountingModule;
  form: any;
  excelData: any;
  selected = ['ny', 'review', 'disputed'];
  options = [
    { text: 'N/Y', value: 'ny' },
    { text: 'Review', value: 'review' },
    { text: 'Disputed', value: 'disputed' },
    { text: 'Completed', value: 'completed' },
    { text: 'Denied', value: 'denied' }
  ];

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  typeFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: '',
    field: 'type'
  });

  containerFilter = new FilterInput({
    condition: FilterConditions.BeginsWith,
    search: '',
    field: 'containerNumber'
  });

  workOrderFilter = new FilterInput({
    condition: FilterConditions.BeginsWith,
    search: '',
    field: 'orderNumber'
  });

  statusesFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: [],
    field: 'orderNumber'
  });

  constructor() {
    super();
    this.form = new FiltersModel();
  }

  search() {
    this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
      this.fromFilter.search,
      [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
    );

    this.toFilter.search = moment(this.toFilter.search, [
      BS_DATEPICKER_FORMAT,
      DATE_API_FORMAT
    ])
      .endOf('day')
      .format(DATE_API_FORMAT);

    this.accountingModule.setFilters(
      TransformFiltersToJson([
        this.typeFilter,
        this.containerFilter,
        this.workOrderFilter,
        this.toFilter,
        this.fromFilter
      ])
    );

    this.accountingModule.search({
      acctType: AccountingTypes[this.$route.params.type]
    });
  }

  async excel() {
    this.accountingModule.setFilters(
      TransformFiltersToJson([this.toFilter, this.fromFilter])
    );

    await this.accountingModule.excel(AccountingTypes[this.$route.params.type]);

    if (this.accountingModule.excelData) {
      window.open(this.accountingModule.excelData.url);
    }
  }

  resetFilters() {
    this.fromFilter = new FilterInput({
      condition: FilterConditions.GreaterOrEqual,
      search: moment()
        .subtract(12, 'days')
        .format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.toFilter = new FilterInput({
      condition: FilterConditions.LessThenOrEqual,
      search: moment().format(BS_DATEPICKER_FORMAT),
      field: DateType.CreatedDate
    });

    this.typeFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'type'
    });

    this.containerFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: '',
      field: 'containerNumber'
    });

    this.workOrderFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: '',
      field: 'orderNumber'
    });

    this.statusesFilter = new FilterInput({
      condition: FilterConditions.Include,
      search: [],
      field: 'orderNumber'
    });

    this.accountingModule.setFilters(
      TransformFiltersToJson([
        this.typeFilter,
        this.containerFilter,
        this.workOrderFilter,
        this.toFilter,
        this.fromFilter
      ])
    );
  }
}
