import { render, staticRenderFns } from "./ChassisFilters.vue?vue&type=template&id=5bd2f1ce&scoped=true&"
import script from "./ChassisFilters.vue?vue&type=script&lang=ts&"
export * from "./ChassisFilters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd2f1ce",
  null
  
)

export default component.exports